<template>
  <div class="article-card-table overflow-auto table-custom-padding" style="margin: 60px">
    <table class="table table-hover ">
      <thead>
      <tr>
        <th>Date</th>
        <th>Release</th>
        <th>Status</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="release_note in allReleaseNotes" class="article-table-row" :key="release_note.id">
        <td style="min-width: 180px;" @click="() =>  openRelease(release_note)">
          {{ new Date(release_note.created).toISOString() }}
        </td>
        <td @click="() => openRelease(release_note)">
          Version-{{ release_note.version }}
        </td>
        <td @click="() =>  openRelease(release_note)">
          {{ release_note.status == true ? 'Active' : 'Inactive' }}
        </td>
      </tr>
      </tbody>
    </table>
    <release-notes-modal v-show="getReleaseModal"/>
  </div>
</template>

<script>
import axios from "@/axios";
import ReleaseNotesModal from "@/components/release_notes/ReleaseNotesModalList";
import {mapGetters} from "vuex";

export default {
  name: "ArticleCardTable",
  components: {
    ReleaseNotesModal

  },
  data() {
    return {
      allReleaseNotes: [],
      showRelease:false
    };
  },
  computed: {
    ...mapGetters('search', ['getReleaseModal']),
  },
  mounted() {
    axios.get(`/accounts/user/release-notes`, {withCredentials: true})
        .then(response => {
          console.log()
          let allReleaseNotes = response.data.release_notes
          this.allReleaseNotes = allReleaseNotes
        });
  },
  methods: {
    openRelease(data){
      this.showRelease=true

      this.$store.dispatch('search/updateModalReleaseState', true);
      this.$store.dispatch('search/updatereleaseContent', data);
    }
  }

}
</script>

<style scoped lang="scss">
.article-card-table {
  background-color: white;
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: .2rem 1rem;
}

.table-custom-padding {
  padding-bottom: 120px;
}

.article-table-row {
  cursor: pointer;
}
</style>
