<template>
  <div class="modal-container" id="releaseModal" @click="event => closeModal(event)">
    <div class="modal-wrapper">

      <div class="modal-content">

        <div class="modal-tab-wrapper">

          <div class="modal-tab-header">
            <div>
              <h5 class="text-uppercase fw-bold text-green mb-2 mb-md-0">
                What's New <trello-icon></trello-icon>
              </h5>
            </div>

            <div class="d-flex align-items-center">
              <button type="button" class="btn-close" aria-label="Close" @click="hideModal"></button>
            </div>
          </div>

          <div class="modal-tab-content">
            <template v-if="page === 1">
              <h6><activity-icon/> Features Added In {{getreleaseContent.version}} </h6>
              <ul v-for="content in getreleaseContent.body.split(';')" :key="content">
                <li>{{content}}</li>
              </ul>


            </template>


          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import {TrelloIcon,ActivityIcon} from 'vue-feather-icons';

export default {
  name: "ReleaseNotesModal",
  components: {

    TrelloIcon,
    ActivityIcon,

  },
  computed: {
    ...mapGetters('search', ['getReleaseModal','getreleaseContent']),
  },

  data() {
    return {
      page: 1
    }
  },

  methods: {

    closeModal(event) {
      this.$store.dispatch('search/updateModalReleaseStateList', false);
    },



    hideModal() {
      this.$store.dispatch('search/updateModalReleaseStateList', false);
    },





  },



}
</script>

<style lang="scss" scoped>
.modal-container {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.modal-wrapper {
  position: relative;
  border: none;
  border-radius: 0;
  outline: 0;
  background: white;
  margin: 0 0;
  width: 100% !important;

  @media (min-width: 991px) {
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 10px;
    margin: 3% auto; /* 15% from the top and centered */
    width: 80%;
    max-width: 900px;
    height: fit-content;
  }


  /* Animate the content of the modal */
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.5s;
  animation-name: animatetop;
  animation-duration: 0.5s
}

.modal-content {
  display: flex;
  flex-direction: row;
  border-radius: 10px;

}

.modal-sidebar-link {
  padding: 14px 5px;

  cursor: pointer;
  transition: 0.25s;

  &:hover {
    background-color: #eaeaea;
  }
}

.modal-sidebar-link--active {
  border-left: 2px solid #4a3288;
  background-color: #f8f8f8;
}

.modal-tab-wrapper {
  width: 100%;
  padding: 15px 20px;
}

.modal-tab-content {
  margin-top: 15px;
}

.modal-tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: white;
  //border: gainsboro 1px solid;

  @media(min-width: 768px) {
    flex-direction: row;
  }
}

.custom-searchbar {
  width: 100%;
  border: 0;
  padding: .4rem 0;
  font-size: 1.5rem;

  &:focus {
    border: none;
    outline: none;
  }
}

@-webkit-keyframes animatetop {
  from {top:-10px; opacity:0}
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-10px; opacity:0}
  to {top:0; opacity:1}
}
</style>
